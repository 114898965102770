import SiteScroller from './modules/SiteScroller'
import SetCalcHeight from './modules/SetCalcHeight'
import CharacterWrapper from './modules/CharacterWrapper'
import WordWrapper from './modules/WordWrapper'
import LoadingAnimation from './modules/LoadingAnimation'
import MenuScroll from './modules/MenuScroll'
import ShowreelHero from './modules/ShowreelHero'
import AccordionTrigger from './modules/AccordionTrigger'
import GalleryOverride from './modules/GalleryOverride'

new LoadingAnimation()
new GalleryOverride()
new MenuScroll('.c-nav-primary')

$('.c-nav-menu').each(function() {
    new MenuScroll('.c-nav-menu')
})

$('.c-showreel-hero').each(function() {
    new ShowreelHero(this)
})

$('.s-page-wrap').each(function () {
    new SiteScroller(this)
})

$('.c-offcanvas.c-offcanvas--sidebar').each( function () {
    new SetCalcHeight(this, '--sidebar-height')
})

$('.c-menu-button__word').each(function () {
    new CharacterWrapper(this)
})

$('.c-quote__text').each(function() {
    new WordWrapper(this)
})

$('.c-menu-button').on('click.dimiss-enquiry', () => $('html').removeClass('sidebar-offcanvas-is-active') )
$('.c-page-header__action').on('click.dimiss-menu', () => $('html').removeClass('menu-offcanvas-is-active') )

$('.c-accordion').each(function () {
    new AccordionTrigger(this, '.c-accordion__anchor', 'is-active')
})

$('[class*=c-media--layout] .c-media__heading').each(function() {
    let html = $(this).html()
    $(this).html('<span>' + html + '</span><span class="overlay">' + html + '</span>')
})


$('.c-content-posts__filter').appendTo('.c-content-posts__headings')

$('.c-content-posts__filter-select').on('change', function() {
    $(this).addClass('has-changed')
        .siblings('.c-content-posts__filter-button').trigger('click')
})

// * Override set to disable Waypoints and it's class additions
$(document).on('cnInitWidgetWaypoints cnInitRowWaypoints', function(e, data) {
    let element = data && data.element || document;

    if(e.type == 'cnInitRowWaypoints') {
        $(element).find('.panel-grid').waypoint({
            handler: function(direction) {
                $(this.element).removeClass('row-is-visible');
                this.destroy()
            },
        })
    }

    if(e.type == 'cnInitWidgetWaypoints') {
        $(element).find('[class*=so-widget]').waypoint({
            handler: function(direction) {
                $(this.element).removeClass('widget-is-visible');
                this.destroy()
            },
        })
    }
})