import $ from 'jquery'

// * Wraps content characters in span
export default class CharacterWrapper {
    constructor(el) {
        this.elements = {
            el: $(el),
        }

        this.wrapCharacters(this.elements.el);
    }

    wrapCharacters(e) {
        let str = e.text().split('')
        
        e.empty()

        $.each(str, function(i, v) {
            e.append($('<span>').text(v));
        })
    }
};
