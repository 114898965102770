import $ from 'jquery'

export default class ShowreelHero {
    constructor(el) {        
        this.showreelHero = $(el)
        this.headings = $(this.showreelHero).find('.c-showreel-hero__heading')
        this.showreelHeroImages = $(this.showreelHero).find('.c-showreel-hero__image-section:not(.two) .c-showreel-hero__image')
        this.looper = $(this.showreelHero).find('.c-showreel-hero__image-section:nth-child(2)')
        this.looperImages = $(this.looper).find('.c-showreel-hero__images')
        this.looperImage = $(this.looper).find('.c-showreel-hero__image')
        this.looperFirstImage = $(this.looper).find('.c-showreel-hero__image:nth-of-type(1)')
        this.looperSecondImage = $(this.looper).find('.c-showreel-hero__image:nth-of-type(2)')
        this.video = $(el).find('video')

        this.breakpoint = {
            width: 1023,
            hieght: 599,
        }
        this.looperAnimation = 'looper-ticker-mobile 20s linear infinite forwards'

        this.init()
    }

    init() {
        this.fadeImages(this.showreelHeroImages)

        if(this.video.length) {
            $('body').on('LoadingAnimationComplete', () => {
                this.video[0].play()
            })
            this.video.one('play', () => {
                this.showreelHero.addClass('is-ready')
                this.wrapWords(this.headings, 0, [0, 3000, 5690, 8290])
            })
        } else {
            this.showreelHero.addClass('is-ready')
            this.wrapWords(this.headings)
        }
        this.wrapImages()
        this.setAnimationDuration()
        this.looperScroll()

        $(window).on('resize', this.resize.bind(this))
        $('.js-play-showreel').on('click', () => {
            this.video[0].play()
        })
    }

    setAnimationDuration() {
        this.looperHeight = this.looper.find('.c-showreel-hero__images').height()
        this.looperImageHeight = this.looper.find('.c-showreel-hero__image').height()
        document.querySelector(":root").style.setProperty('--looper-animate', this.looperHeight * -1 + "px")
        document.querySelector(":root").style.setProperty('--looper-duration', this.looperHeight / 100 + "s")
        document.querySelector(":root").style.setProperty('--looper-image-height', this.looperImageHeight + "px")
    }

    fadeImages(e) {
        let i = 0
        let images = e.slice(0)
        let sliderDelay = images.length

        while(images.length != 0) {
            let delay = (.3 + (i * .1)) + 's'
            let index = Math.floor(Math.random() * images.length)
            let image = images[index]
            
            $(image).css('transition-delay', delay)
            images.splice(index, 1)
            i++
        }

        $('.c-showreel-hero__image-section.two').css('transition-delay', (.3 + (sliderDelay * .1) + 's'))
    }

    wrapImages() {
        let ticker = $('<div class="c-showreel-hero__images looper-ticker"/>')
        
        ticker.append(this.looperImage)
        ticker.append(this.looperFirstImage.clone(true))
        ticker.append(this.looperSecondImage.clone(true))

        // * applied fix for mobile (iOS) loading issues
        if(window.matchMedia('(max-width: 1023px)').matches || window.matchMedia('max-height: 599px').matches) {
            setTimeout(() => {
                ticker.addClass('trigger-scroll')
            }, 500);
        }
        
        $(this.looper).html(ticker)

        $(document).on('cnImgixReady.showreel', (event, fluidDefaults) => {
            if(this.looper[0]) {
                imgix.fluid(this.looper[0], fluidDefaults);
            }
        })
    }

    wrapWords(e, delay = 6000, timings) {
        e.each(function(i, v) {
            let interval = (timings && timings.length >= e.length ? timings[i] : (1500 * i))

            $(this).addClass("c-showreel-hero__heading--" + i)

            setTimeout(() => {
                $(this).toggleClass('active')
                $(this).prev().addClass('shown')
            }, delay + interval)

            setTimeout(() => {
                $(this).prev().toggleClass('active')
            }, (delay + interval) - 500)

            let str = $(this).text().split(' ')

            $(this).empty()

            for(i=0; i < str.length; i++) {
                $(this).append($('<span>').text(str[i] + (i == str.length - 1 ? '': ' ')))
            }
        })
    }

    looperScroll() {
        this.looper.on('scroll.infinite', () => {
            let looperEl = this.looper[0]
            let looperScrollTop = looperEl.scrollTop
            let looperScrollBottom = looperEl.scrollHeight - looperEl.clientHeight - this.looperHeight
            
            if(looperScrollTop <= 0) {
                looperEl.scrollTop = looperScrollBottom
            }
            
            else if(looperEl.scrollTop >= looperScrollBottom) {
                looperEl.scrollTop = 1
            }
        })
    }

    resize() {
        this.setAnimationDuration()
    }
};