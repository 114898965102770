import $ from 'jquery'

export default class GalleryOverride {
    constructor() {
        $(document).on('cnInitGallery', () => {

            Isotope.Item.prototype._create = function() {
                // assign id, used for original-order sorting
                this.id = this.layout.itemGUID++;
                // transition objects
                this._transn = {
                    ingProperties: {},
                    clean: {},
                    onEnd: {}
                };
                this.sortData = {};
            };

            Isotope.Item.prototype.layoutPosition = function() {
                this.emitEvent( 'layout', [ this ] );
            };

            Isotope.prototype.arrange = function( opts ) {
                // set any options pass
                this.option( opts );
                this._getIsInstant();
                // just filter
                this.filteredItems = this._filter( this.items );
                // flag for initalized
                this._isLayoutInited = true;
            };


            Isotope.prototype._postLayout = function() {
                this.resizeContainer();
                $(this.element).trigger('arrangeComplete')
            };

            Isotope.LayoutMode.create('none');

            $('.js-grid-container').isotope({
                itemSelector: '.js-thumbnail',
                layoutMode: 'none'
            }).on('arrangeComplete.gallery', () => {
                $(window).trigger('refreshScrollTrigger')
                this.modItemIndexes()
                // this.getColumns()
            })

        })
    }
    modItemIndexes() {
        let columns = 0;
        let grid = $('.js-grid-container')

        $.each(grid.data('isotope').filteredItems, function (index, item) {
            var el = $(item.element);
            let mod = index % 9

            el.attr('data-grid-item-index-mod', mod);

            if(mod == 3 || mod == 4 || (mod == 0 && index != 0)) {
                columns += 1;
            } else {
                columns += .5;
            }
        });

        grid[0].style.setProperty('--columns', Math.round(columns));
    }
};
