import $ from 'jquery'

export default class AccordionTrigger {
    constructor(el, trigger, targetClass) {
        this.elements = {
            base: $(el),
            trigger: $(el).find(trigger),
            class: targetClass
        }

        this.bindEvents()
    }

    bindEvents() {
        this.elements.trigger.on('click', (e) => this.checkState(e))
    }

    checkState(e) {
        e.preventDefault()

        // * check state being overridden by core class toggle logic - it removes it and readds it immediately when trying to close an active item
        this.elements.trigger.not(e.currentTarget).parent().removeClass(this.elements.class)
    }
};