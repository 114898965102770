import $ from 'jquery'

// * Wraps content words in span
export default class WordWrapper {
    constructor(el) {
        this.elements = {
            el: $(el),
        }

        this.wrapWords(this.elements.el);
    }

    wrapWords(e) {
        let str = e.text().split(' ')

        e.empty()

        $.each(str, function(i, v) {
            let setDelay = (.3 + (i * .1)) + 's'

            e.append($('<span>').text(v + ' ').css('transition-delay', setDelay));
        })

        if(this.elements.el.parent('.c-quote')) {
            let citeDelay = (.3 + ((str.length) * .1)) + 's'

            $('.c-quote__cite').css('transition-delay', citeDelay)
        }
    }
};
