import $ from 'jquery'

export default class LoadingAnimation {
    constructor() {
        this.animationTimeout = setTimeout(this.setComplete.bind(this, 'animated'), 2000)
        this.loaded = this.animated = false

        $(window).on('load', this.setComplete.bind(this, 'loaded'))
    }

    setComplete(state) {
        this[state] = true
        this.finished()
    }

    finished() {
        if(this.loaded && this.animated) {
            $('body').addClass('has-loaded')
            $('body').trigger('LoadingAnimationComplete')
        } else {
            setTimeout(this.finished.bind(this), 500)
        }
    }
};
