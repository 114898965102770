import $ from 'jquery'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default class SiteScroller {
    constructor(el) {
        this.elements = {
            base: $(el),
            wrapper: $(el).find('.panel-layout').eq(0), // * Targets the first only
            slides: $(el).find('.panel-layout').eq(0).children('.panel-grid'), // * only direct children
            primarySections: $(el).find('.panel-layout').eq(0).children('.panel-grid').find('.c-row--style-primary'), // * gets all primary specific sections

            scrollCheck: $('.js-horizontal-scroll-check'),
        }
        
        // * global vars
        this.horizontalScroll = false
        this.duration = ''
        this.xOffset = ''
        this.yOffset = ''
        this.backgroundFadeBuffer = 0.5

        // * gsap util array conversions
        this.slides = gsap.utils.toArray(this.elements.slides)
        this.primarySections = gsap.utils.toArray(this.elements.primarySections.parent('.panel-grid'))
        this.mediaGroupHeaders = gsap.utils.toArray($(el).find('.panel-layout').eq(0).find('.c-media-group__header'))

        this.setScrollDirection()
        this.calculateScroll()
        this.createScene()
    }

    createScene() {
        ScrollTrigger.matchMedia({
            "(min-width: 1024px) and (min-height: 600px)": () => {
                this.siteScroll = gsap.to(this.elements.wrapper, {
                    x: () => $(window).width(),
                    ease: 'none',
                    xPercent: -100,

                    scrollTrigger: {
                        trigger: this.elements.wrapper,
                        pin: true,
                        scrub: true,
                        anticipatePin: 1,
                        preventOverlaps: true,
                        invalidateOnRefresh: true,
                        start: 'top top',
                        end: () => this.getScrollDuration(),
                    }
                })

                this.stickHeaders()

                return () => {
                    // console.log("leave");

                    /**
                    * * Works on reload tho :D
                    * 
                    * ? disable would be better on resize out of the breakpoint
                    * ? - kill() doesn't allow for persisting animations
                    * 
                    * ToDo: resolve resize issues as it currently doesn't retrigger everything (so color tweens don't reset correctly)
                     **/
                    //    this.siteScroll.kill();
                    this.getScrollDuration() // ! however, this is causing the end to be additive on resize
                    this.resetHeaders()
                }
            },            
            "all": () => {
                // * Used to detect when slides are in viewport and assigns visibility class (can be used for animation triggers)
                this.slides.forEach((slide, i) => {
                    // console.log(`hori slide ` + i + ' || ', slide)
                    gsap.to(slide, {
                        ease: 'none',
                        scrollTrigger: {
                            trigger: slide,
                            containerAnimation: this.siteScroll,
                            invalidateOnRefresh: true,
                            toggleClass: { targets: slide, className: "slide-is-visible" },
                            onEnter: () => {
                                $(slide).addClass('slide-is-seen')
                            }
                            // markers: true

                        }
                    })
                })
            }
        });

        $(window).on('scroll', () => {
            this.checkBackgroundColour()
        })
        .trigger('scroll')

        $(window).on('refreshScrollTrigger',  () => {
            setTimeout(() => {
                ScrollTrigger.refresh()
            }, 100)
        })

    }

    checkBackgroundColour() {
        let whiteBGSlides = $('.c-row--style-primary').parents('.panel-grid').filter((i, el) => {
            let inView = ScrollTrigger.isInViewport(el, 0, this.horizontalScroll)
            let howMuch = 0

            if(inView){
                howMuch = ScrollTrigger.positionInViewport(el, (this.horizontalScroll ? "right" : "bottom"), this.horizontalScroll)
            }

            return inView && howMuch > this.backgroundFadeBuffer
        })

        if(whiteBGSlides.length > 0) {
            $('body').addClass('page-color-changed')
            gsap.to('body', {
                '--color': '#1b1b1b',
                '--lines-color': '#E3E3E3',
                '--backgroundColor': '#ffffff',
                overwrite: 'auto',
            })
        } else {
            $('body').removeClass('page-color-changed')
            gsap.to('body', {
                '--color': '#ffffff',
                '--lines-color': '#333333',
                '--backgroundColor': '#1b1b1b',
                overwrite: 'auto',
            })
        }
    }

    stickHeaders() {
        this.mediaGroupHeaders.forEach((header, i) => {
            let parent = $(header).parents('.c-media-group')

            gsap.to(header, {
                ease: 'none',
                x: () => parent.outerWidth(),
                // autoAlpha: 0,

                scrollTrigger: {
                    trigger: header,
                    containerAnimation: this.siteScroll,
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: 'left left',
                    toggleClass: { targets: header, className: "is-stuck" },
                    end: () => '+=' + parent.outerWidth(),
                    onUpdate: (self) => {
                        // console.log("self:", self)
                        let fadeStart = .65
                        let fadeEnd = .80
                        let fadeRate = 1 / (fadeEnd - fadeStart)

                        if(self.progress > fadeStart){
                            let progress = (self.progress - fadeStart) * fadeRate
                            let opacity = 1 - progress
                            $(header).find('.c-media-group__heading').css('opacity', opacity)
                        } else {
                            $(header).find('.c-media-group__heading').css('opacity', 1)
                        }

                    }
                }
            })
        })
    }

    resetHeaders() {
        this.mediaGroupHeaders.forEach((header, i) => {
            $(header).find('.c-media-group__heading').css('opacity', 1)
        })
    }

    setScrollDirection() {
        // * sets global to truthy falsey statement
        this.horizontalScroll = this.elements.scrollCheck.is(':visible')
    }

    endScrub(el) {
        // * if horizontal scroll set end value => calculated to remove margin and add 33vw to start of element
        if(this.horizontalScroll) {
            let start = el.offsetWidth
            let margin = ($(el).children('.c-row--style-primary').outerWidth(true) - $(el).children('.c-row--style-primary').outerWidth())
            let viewport = $(window).width() * .33333
            
            return  '+=' + (start - margin + viewport)
        }
        else {
            return 'bottom top'
        }
    }

    // * adds all individual slide (row) widths together to calculate
    setContainerWidth() {
        let combinedSlideWidth = 0

        this.elements.slides.each(function () {
            combinedSlideWidth += $(this).outerWidth()
        })

        return combinedSlideWidth
    }

    // * adds all individual slide (row) heights together to calculate
    setContainerHeight() {
        let combinedSlideHeight = 0

        this.elements.slides.each(function() {
            combinedSlideHeight += $(this).outerHeight()
        })

        return combinedSlideHeight
    }
    
    /**
    * * This section will get the end result for the main timeline and
    * * will also update global dependencies based on the scrollDirection
    * 
    * * if the scroll direction is Horizontal(true) return the x and allow for pinned
    * * scrolling else if false return the y value and allow for 'native' scrolling
    **/
    calculateScroll() {
        if(this.horizontalScroll) {
            this.xOffset = this.setContainerWidth()
            this.yOffset = $(window).height()

            // * rewrites the wrappers width on refresh (resize)
            this.elements.wrapper.width(this.xOffset)

            return this.xOffset

        } else if (!this.horizontalScroll) {
            /**
            * *  used to reset the container width
            * * now overrides to be 100vw not '$(window).height()' (as this returns a fixed value)
            **/
            this.xOffset = '100vw'
            this.yOffset = this.setContainerHeight()
            
            // * rewrites the wrappers width on refresh (resize)
            this.elements.wrapper.width(this.xOffset)

            return this.yOffset
        }
    }

    getScrollDuration() {
        this.setScrollDirection()
    
        return '+=' + this.calculateScroll()
    }
};
