import $ from 'jquery'
class MenuScroll {
    constructor(menu) {
        this.menu = $(menu)
        this.switcher = ''

        if(menu == '.c-nav-primary') {
            this.switcher = 'navPrimary-'
        } else if(menu == '.c-nav-menu') {
            this.switcher = 'navMenu-'
        }
        
        this.content = $(this.menu).find('> .menu')
        this.toggleItems = '.menu__item--has-children > a'
        this.scrollEnabled = this.getScrollEnabled()
        
        this.init()
    }

    init() {
        this.build()
        this.setAnimationDuration()
        this.userScroll()
        this.menuToggles()

        $(window).on('resize', this.resize.bind(this))
    }

    setAnimationDuration() {
        this.menuHeight = this.content.height()
        
        document.querySelector(":root").style.setProperty('--' + this.switcher + 'menu-animate', this.menuHeight * -1 + "px");
        document.querySelector(":root").style.setProperty('--' + this.switcher + 'menu-duration', this.menuHeight / 100 + "s");
    }

    build() {
        let ticker = $('<div class="menu-ticker"/>')
        let scrollBuffer = 2

        this.content.removeAttr('id')
        ticker.append(this.content)

        for (let i = 0; i < scrollBuffer; i++) {
            ticker.append(this.content.clone())
        }

        $(this.menu).html(ticker)
    }

    resize() {
        this.setAnimationDuration()
        this.scrollEnabled = this.getScrollEnabled()
    }

    userScroll() {
        this.menu.on('scroll.infinite', () => {
            if(!this.scrollEnabled) return

            let menuEl = this.menu[0]
            let menuScrollTop = menuEl.scrollTop
            let menuScrollBottom = menuEl.scrollHeight - menuEl.clientHeight - this.menuHeight

            if(menuScrollTop <= 0) {
                menuEl.scrollTop = menuScrollBottom
            }

            else if(menuEl.scrollTop >= menuScrollBottom) {
                menuEl.scrollTop = 1
            }
        })
    }

    getScrollEnabled() {
        return $(window).width() > 800
    }

    pause() {
        this.menu.addClass('is-paused')
    }

    resume() {
        this.menu.removeClass('is-paused')
        this.menu.find('.menu__item').removeClass('is-active')
    }

    menuToggles() {
        this.menu.on('click', this.toggleItems, (e) => {
            e.preventDefault()

            this.pause()
            $(e.currentTarget).closest('.menu__item').toggleClass('is-active')
        })
        this.menu.on('mouseleave', this.resume.bind(this))
    }
}

export default MenuScroll