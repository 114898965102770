import $ from 'jquery'

// * Called by function for each element to set height for supplied SCSS variable
export default class SetCalcHeight {
    constructor(el, property) {
        this.elements = {
            target: el,
            property: property
        }

        this.calcHeight()
 
        window.addEventListener('resize', this.calcHeight.bind(this))
    }

    calcHeight() {
        let setGetHeight = $(this.elements.target).outerHeight()

        document.documentElement.style.setProperty(`${this.elements.property}`, `${setGetHeight}px`)
    }
}